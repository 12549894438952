import { MFAAuthenticatorStatus } from 'enums'

export enum MFAType {
  email = 'email',
  totp = 'totp',
  sms = 'sms',
  webAuthn = 'webauthn',
  backupCodes = 'backup_code',
  push = 'push',
}

interface MFAAuthenticatorBase {
  id: string
  created: string
  last_success: string
  verified: boolean
  status: MFAAuthenticatorStatus
}

export interface MFAAuthenticatorPush extends MFAAuthenticatorBase {
  type: MFAType.push
  name: string
}
export interface MFAAuthenticatorEmail extends MFAAuthenticatorBase {
  type: MFAType.email
  email: string
}

export interface MFAAuthenticatorSMS extends MFAAuthenticatorBase {
  type: MFAType.sms
  phone_number: string
}

export interface MFAAuthenticatorTOTP extends MFAAuthenticatorBase {
  type: MFAType.totp
  name: string
}

interface MFAAuthenticatorWebAuthn extends MFAAuthenticatorBase {
  type: MFAType.webAuthn
  name: string
}

export interface MFAAuthenticatorBackupCodes extends MFAAuthenticatorBase {
  type: MFAType.backupCodes
  codes_left: number
}

export type MFAAuthenticator =
  | MFAAuthenticatorPush
  | MFAAuthenticatorEmail
  | MFAAuthenticatorSMS
  | MFAAuthenticatorTOTP
  | MFAAuthenticatorWebAuthn
  | MFAAuthenticatorBackupCodes

export interface MFADataState {
  authenticators: MFAAuthenticator[]
}
