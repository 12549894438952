import type Stripe from 'stripe'

import { StripeTaxId } from 'features/payment-methods/modules/types'
import { TaxIdType } from 'features/stripe/ui/types'
import { StripeData } from 'types/types'

import {
  deleteRequestWithCookies,
  fetchRequestWithCookies,
  postRequestWithCookies,
  putRequestWithCookies,
} from '../axios'

type TaxIdData = {
  type: TaxIdType
  value: string
}

const {
  api: { billing },
} = __CONFIG__

export class BillingTaxIdApi {
  get = async () => {
    const url = new URL(billing.paths.taxId, billing.base)

    const { data } = await fetchRequestWithCookies<StripeTaxId>(url.href)
    return data
  }

  create = async (data: TaxIdData) => {
    const url = new URL(billing.paths.taxId, billing.base)
    await postRequestWithCookies<TaxIdData, StripeData<Stripe.TaxId>>(
      url.href,
      data
    )
  }

  update = async (data: TaxIdData) => {
    const url = new URL(billing.paths.taxId, billing.base)
    await putRequestWithCookies<TaxIdData, StripeData<Stripe.TaxId>>(
      url.href,
      data
    )
  }

  delete = async () => {
    const url = new URL(billing.paths.taxId, billing.base)

    await deleteRequestWithCookies(url.href)
  }
}
