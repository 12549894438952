import { ActivityState } from 'pages/activity/module/types'
import { fetchRequestWithCookies } from './axios'

const {
  api: { sso, ssoUi },
} = __CONFIG__

export class ActivityApi {
  getAll = async (): Promise<ActivityState> => {
    const url = new URL(sso.paths.activity, ssoUi.base)
    url.searchParams.append('source', 'sso')

    const { data } = await fetchRequestWithCookies<any>(url.href)
    return data
  }
}
