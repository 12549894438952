import React from 'react'

import { validStripeCountries } from 'features/stripe/ui/regions'
import { RoundFlag } from 'components/RoundFlag'
import { CustomTaxId } from 'features/payment-methods/modules/types'
import { StripeCountryDropdownOption } from 'features/stripe/ui/types'

import { RegionDropdownOption, TaxIdState } from '../types'

const STRIPE_TESTING_VALUES = {
  successful: '000000000',
  failing: '111111111',
  pending: '222222222',
}

export const disableAddOrUpdatedButton = (
  taxId: CustomTaxId | null | undefined,
  { value, country }: TaxIdState,
  isTaxIdFormatValid: boolean
) => {
  const isAddDisabled = !isTaxIdFormatValid
  const isUpdateDisabled =
    value === taxId?.value && country === taxId?.country?.toLowerCase()

  return isAddDisabled || isUpdateDisabled
}

const parseLabel = (
  { name, value }: StripeCountryDropdownOption,
  hasCountryMultipleTaxIdFormats?: boolean
) => {
  let parsedValue = name
  if (value === 'eu_vat' && hasCountryMultipleTaxIdFormats) {
    parsedValue += ' (EU)'
  }
  return parsedValue
}

const renderDropdownOption = (
  fieldsForDropdownOption: StripeCountryDropdownOption,
  hasCountryMultipleTaxIdFormats?: boolean
) => {
  const { code, value, taxFormat, taxPlaceholder } = fieldsForDropdownOption

  const parsedLabel = parseLabel(
    fieldsForDropdownOption,
    hasCountryMultipleTaxIdFormats
  )

  return {
    value: `${code}-${value}`,
    label: parsedLabel,
    image: <RoundFlag countryCode={code} size="small" />,
    type: value,
    taxFormat: taxFormat,
    taxPlaceholder: taxPlaceholder,
    country: code,
  }
}

export const countryDropdownOptions = validStripeCountries
  .flatMap((country) => {
    if ('taxId' in country && country.taxId) {
      const { taxId, code, name } = country
      if (taxId.length > 1) {
        return taxId.map((tax) =>
          renderDropdownOption({ ...tax, code, name }, true)
        )
      }
      return renderDropdownOption({ ...taxId[0], code, name }, false)
    }
  })
  .filter((region) => region !== undefined) as RegionDropdownOption[]

export const getValidationRegex = (dropdownValue: string) => {
  return countryDropdownOptions.find((region) => region.value === dropdownValue)
    ?.taxFormat
}

export const deriveCountryFromValue = (value: TaxIdState['value']): string => {
  return (
    countryDropdownOptions.find((country) => country.taxFormat.test(value))
      ?.value || ''
  )
}

const getIsValidForEmployee = (value: string) => {
  const { successful, failing, pending } = STRIPE_TESTING_VALUES
  return value === successful || value === failing || value === pending
}

export const assertIfTaxIdIsValid = (value: string, regex?: RegExp) => {
  const isTaxIdValid = !!regex?.test(value)
  const isValidForEmployee = isTaxIdValid || getIsValidForEmployee(value)
  return { isValidForEmployee, isTaxIdValid }
}
