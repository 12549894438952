import React from 'react'
import type Stripe from 'stripe'
import { ProductName } from 'types/enums'
import { getSubscriptionProductLine } from 'features/subscriptions/fullSubscriptions/utils'
import { useSelector } from 'react-redux'
import { TruncatedText } from 'components/TruncatedText'
import { selectOwnedUcoreDevices } from 'features/devices/devices'
import { useBillingProductsQuery } from 'store/queries/useBillingProductsQuery'

interface Props {
  invoice: Stripe.Invoice
  productId: string
}

const deviceNameFallback = '-'

export const DeviceNameCell: React.FC<Props> = ({ invoice, productId }) => {
  const { getProduct } = useBillingProductsQuery()
  const product = getProduct(productId)
  const devices = useSelector(selectOwnedUcoreDevices)

  const metadataProduct =
    invoice.metadata?.ui_product_line ||
    invoice.lines?.data?.[0]?.metadata.ui_product_line

  const productLine = getSubscriptionProductLine(product?.name, metadataProduct)

  const uiDeviceName = invoice.subscription_details?.metadata?.ui_device_name
  const controller = invoice.subscription_details?.metadata?.controller
  const site =
    invoice.subscription_details?.metadata?.site ??
    invoice.subscription_details?.metadata?.site_name
  const deviceName =
    invoice.subscription_details?.metadata?.deviceName ??
    invoice.subscription_details?.metadata?.device_name
  const deviceId = invoice.subscription_details?.metadata?.device_uuid
  const deviceShortname =
    invoice.subscription_details?.metadata?.device_shortname
  const subscriptionDevice = devices.find(
    (device) => device.hardwareId === deviceId
  )

  const getDeviceName = (productLine: ProductName) => {
    if (productLine === ProductName.ULTE && controller) {
      if (site && site !== 'Default') {
        return `${controller} - ${site}`
      }
      return controller
    }

    if (productLine === ProductName.ENHANCED_TREAT_UPDATES) {
      if (site && site !== 'Default' && deviceName) {
        return `${deviceName} - ${site}`
      }
      return deviceName ?? deviceShortname
    }

    if (uiDeviceName) {
      return uiDeviceName
    }

    if (deviceName) {
      return deviceName
    }

    if (subscriptionDevice && subscriptionDevice?.shadow?.state.reported.name) {
      return subscriptionDevice?.shadow?.state.reported.name
    }

    return deviceNameFallback
  }

  return <TruncatedText text={getDeviceName(productLine)} truncateAt={11} />
}
