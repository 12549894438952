import { ProductName } from 'types/enums'

export const getSubscriptionProductLine = (
  productName?: string,
  metaDataName?: string
): ProductName => {
  if (
    productName === ProductName.UNIFI_TALK ||
    metaDataName?.toLowerCase() === ProductName.TALK
  ) {
    return ProductName.UNIFI_TALK
  }

  if (
    metaDataName === ProductName.UNIFI_HOSTING ||
    productName === ProductName.HOSTING
  ) {
    return ProductName.HOSTING
  }

  if (productName === ProductName.MOBILITY) {
    return ProductName.MOBILITY
  }

  if (productName === 'ULTE' || metaDataName?.toLowerCase() === 'ulte') {
    return ProductName.ULTE
  }

  if (productName === ProductName.SITE_SUPPORT) {
    return ProductName.SITE_SUPPORT
  }

  if (
    productName === ProductName.UISP_HOSTING ||
    productName === ProductName.UISP_CLOUD
  ) {
    return ProductName.UISP_CLOUD
  }

  if (
    productName === ProductName.ENHANCED_TREAT_UPDATES ||
    productName === 'Intrusion Prevention'
  ) {
    return ProductName.ENHANCED_TREAT_UPDATES
  }

  return ProductName.UNKNOWN
}
