import { StripeCountry, StripeRegionCode } from '../types'

const {
  featureFlags: { areExtraAUCountriesEnabled },
} = __CONFIG__

const extraCountries: Readonly<StripeCountry[]> = [
  {
    code: 'nz',
    name: 'New Zealand',
    region: StripeRegionCode.AU,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'cx',
    name: 'Christmas Island',
    region: StripeRegionCode.AU,
    taxId: [] as StripeCountry['taxId'],
  },
]

export const auStripeCountries: Readonly<StripeCountry[]> = [
  {
    code: 'au',
    name: 'Australia',
    region: StripeRegionCode.AU,
  },
  ...(areExtraAUCountriesEnabled ? extraCountries : []),
]
