import React, { useState } from 'react'

import { ALL_KEY } from 'sharedConstants'
import { useSessionStorage } from 'utils/useSessionStorage'

import { SortType } from './types'

export const LISTINGS_PER_PAGE_OPTIONS = [5, 10, 20]
export const MINIMUM_LISTINGS_PER_PAGE = LISTINGS_PER_PAGE_OPTIONS[0]
const DEFAULT_LISTINGS_PER_PAGE = LISTINGS_PER_PAGE_OPTIONS[1]

type BackupsContext = {
  devicesPerPage: number
  setDevicesPerPage?: React.Dispatch<React.SetStateAction<number>>
  selectedDevice: string
  setSelectedDevice?: React.Dispatch<React.SetStateAction<string>>
  deletedBackups: number
  setDeletedBackups?: React.Dispatch<React.SetStateAction<number>>
  downloadLoading?: string
  setDownloadLoading?: React.Dispatch<React.SetStateAction<string | undefined>>
  downloadError?: JSX.Element | undefined
  setDownloadError?: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >
  currentPage: number
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>
  selectedSort: SortType
  setSelectedSort?: React.Dispatch<React.SetStateAction<SortType>>
  // Archived Backups
  devicesPerPageRemoved: number
  setDevicesPerPageRemoved?: React.Dispatch<React.SetStateAction<number>>
  selectedDeviceRemoved: string
  setSelectedDeviceRemoved?: React.Dispatch<React.SetStateAction<string>>
  currentPageRemoved: number
  setCurrentPageRemoved?: React.Dispatch<React.SetStateAction<number>>
  selectedSortRemoved: SortType
  setSelectedSortRemoved?: React.Dispatch<React.SetStateAction<SortType>>
}

export const initialSettings = {
  devicesPerPage: DEFAULT_LISTINGS_PER_PAGE,
  selectedDevice: ALL_KEY,
  deletedBackups: 0,
  currentPage: 1,
  // Archived Backups
  devicesPerPageRemoved: DEFAULT_LISTINGS_PER_PAGE,
  selectedDeviceRemoved: ALL_KEY,
  currentPageRemoved: 1,
}

export const BackupsContext = React.createContext<BackupsContext>({
  ...initialSettings,
  setDevicesPerPage: undefined,
  setSelectedDevice: undefined,
  setDeletedBackups: undefined,
  downloadLoading: undefined,
  setDownloadLoading: undefined,
  downloadError: undefined,
  setDownloadError: undefined,
  setCurrentPage: undefined,
  selectedSort: 'desc-date',
  setSelectedSort: undefined,
  // Archived Backups
  setDevicesPerPageRemoved: undefined,
  setSelectedDeviceRemoved: undefined,
  setCurrentPageRemoved: undefined,
  selectedSortRemoved: 'desc-date',
  setSelectedSortRemoved: undefined,
})

export const BackupsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [storedDevicesPerPage] = useSessionStorage('devicesPerPage')
  const [downloadLoading, setDownloadLoading] = useState<string | undefined>(
    undefined
  )
  const [downloadError, setDownloadError] = useState<JSX.Element | undefined>(
    undefined
  )
  const [devicesPerPage, setDevicesPerPage] = useState<number>(
    storedDevicesPerPage ?? initialSettings.devicesPerPage
  )
  const [selectedDevice, setSelectedDevice] = useState(
    initialSettings.selectedDevice
  )
  const [deletedBackups, setDeletedBackups] = useState(
    initialSettings.deletedBackups
  )
  const [currentPage, setCurrentPage] = useState(initialSettings.currentPage)
  const [selectedSort, setSelectedSort] = useState<SortType>('desc-date')

  // Archived Backups
  const [storedDevicesPerPageRemoved] = useSessionStorage(
    'devicesPerPageRemoved'
  )
  const [devicesPerPageRemoved, setDevicesPerPageRemoved] = useState<number>(
    storedDevicesPerPageRemoved ?? initialSettings.devicesPerPageRemoved
  )
  const [selectedDeviceRemoved, setSelectedDeviceRemoved] = useState(
    initialSettings.selectedDevice
  )
  const [currentPageRemoved, setCurrentPageRemoved] = useState(
    initialSettings.currentPageRemoved
  )
  const [selectedSortRemoved, setSelectedSortRemoved] =
    useState<SortType>('desc-date')

  return (
    <BackupsContext.Provider
      value={{
        devicesPerPage,
        setDevicesPerPage,
        selectedDevice,
        setSelectedDevice,
        deletedBackups,
        setDeletedBackups,
        downloadLoading,
        setDownloadLoading,
        downloadError,
        setDownloadError,
        currentPage,
        setCurrentPage,
        selectedSort,
        setSelectedSort,
        // Archived Backups
        devicesPerPageRemoved,
        setDevicesPerPageRemoved,
        selectedDeviceRemoved,
        setSelectedDeviceRemoved,
        currentPageRemoved,
        setCurrentPageRemoved,
        selectedSortRemoved,
        setSelectedSortRemoved,
      }}
    >
      {children}
    </BackupsContext.Provider>
  )
}
