import { fetchRequestWithCookies, patchRequestWithCookies } from './axios'

const {
  api: { megaphone },
} = __CONFIG__

type Announcement = {
  announcementId: string
  title: string
  description: string
  externalLink: string
  isRead: boolean
  media: { image?: string }
}

interface AnnouncementData {
  data: Announcement[]
}

export class AnnouncementsApi {
  getAll = async () => {
    const url = new URL(megaphone.paths.announcements, megaphone.base)
    const { data } = await fetchRequestWithCookies<AnnouncementData>(url.href)
    return data
  }

  markAsRead = async (id: string) => {
    const url = new URL(
      `${megaphone.paths.announcements}/${id}/read`,
      megaphone.base
    )
    await patchRequestWithCookies(url.href)
  }
}
