import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '@ubnt/ui-components/styles/normalize.css'
import '@ubnt/ui-components/styles/reset.css'
import GlobalStyles from 'theme/GlobalStyles'
import { authRootSaga } from 'features/auth/modules/auth'
import { assuranceLevelRootSaga } from 'features/auth/modules/assuranceLevel'
import { createAccountRootSaga } from 'features/auth/modules/createAccount'
import { profilePictureRootSaga } from 'features/profile-picture/module/profilePicture'
import { generateBackupRootSaga } from 'pages/security/components/BackupCodes/modules'
import { rolesRootSaga } from 'features/early-access/module/roles'
import { deleteAccountRootSaga } from 'features/delete-account/module/deleteAccount'
import GlobalErrorBoundry from 'core/error-handling/GlobalErrorBoundry'
import { ReCaptchaProviderConnected } from 'components/ReCaptcha'
import { reauthMFARootSaga } from 'features/auth/modules/reauth'
import { loginPasskeyRootSaga } from 'pages/security/components/Passkey/modules/loginPasskey'
import { devicesRootSaga } from 'features/devices/devices'
import { addressesRootSaga } from 'features/addresses/module/addresses'
import { deviceBackupsRootSaga } from 'features/backups/module/backups'
import { changeEmailRootSaga } from 'pages/security/changeEmail'
import { BackupsProvider } from 'features/backups/BackupsContext'
import { MotifProvider } from 'motif/MotifProvider'
import { openTicketsRootSaga } from 'modules/zendesk/openUserTickets'
import { initiateEmailRootSaga } from 'pages/security/components/Email/modules/initiateEmail'
import { removeEmailRootSaga } from 'pages/security/components/Email/modules/removeEmail'
import { sendEmailRootSaga } from 'pages/security/components/Email/modules/sendEmail'
import { verifyEmailRootSaga } from 'pages/security/components/Email/modules/verifyEmail'
import { initiatePushRootSaga } from 'pages/security/components/Push/modules/initiatePush'
import { loginPushRootSaga } from 'pages/security/components/Push/modules/loginPush'
import { removePendingPushRootSaga } from 'pages/security/components/Push/modules/removePendingPush'
import { removePushRootSaga } from 'pages/security/components/Push/modules/removePush'
import { verifyPushRootSaga } from 'pages/security/components/Push/modules/verifyPush'
import { removeMFASMSRootSaga } from 'pages/security/components/SMS/modules/removeMFASMS'
import { sendMFASMSRootSaga } from 'pages/security/components/SMS/modules/sendMFASMS'
import { removePasskeyRootSaga } from 'pages/security/components/Passkey/modules/removePasskey'
import { setupPasskeyRootSaga } from 'pages/security/components/Passkey/modules/setupPasskey'
import { initiateTOTPRootSaga } from 'pages/security/components/TOTP/modules/initiateTOTP'
import { removeTOTPRootSaga } from 'pages/security/components/TOTP/modules/removeTOTP'
import { verifyTOTPRootSaga } from 'pages/security/components/TOTP/modules/verifyTOTP'
import { closedTicketsRootSaga } from 'modules/zendesk/closedUserTickets'
import { ccdTicketsRootSaga } from 'modules/zendesk/ccdUserTickets'
import { MediaSizingProvider } from 'components/mediaSizing/MediaSizingContext'
import { trustedDevicesRootSaga } from 'modules/trustedDevices'
import { uiTaxIdsRootSaga } from 'features/subscriptions/module/uiTaxIds'
import { BusinessProfileRootSaga } from 'features/subscriptions/module/businessProfile'
import packageJSON from '../package.json'
import storeConfigs from './store'
import { AppIntlProvider } from './pages/IntlProvider'
import App from './pages/App'
import {
  subscribeProfileUpdateDoneSaga,
  subscribeToSetProfileSaga,
} from './modules/profile'
import { mfaRootSaga } from './modules/mfa'
import { LocaleProvider } from 'modules/LocaleContext'
import { trustDeviceRootSaga } from 'modules/trustDevice'
import { loginIssueFeedbackRootSaga } from 'features/auth/modules/loginIssueFeedback'

const queryClient = new QueryClient()

const { store, sagaMiddleware } = storeConfigs

sagaMiddleware.run(authRootSaga)
sagaMiddleware.run(initiateTOTPRootSaga)
sagaMiddleware.run(subscribeToSetProfileSaga)
sagaMiddleware.run(createAccountRootSaga)
sagaMiddleware.run(subscribeProfileUpdateDoneSaga)
sagaMiddleware.run(mfaRootSaga)
sagaMiddleware.run(reauthMFARootSaga)
sagaMiddleware.run(removeTOTPRootSaga)
sagaMiddleware.run(verifyTOTPRootSaga)
sagaMiddleware.run(profilePictureRootSaga)
sagaMiddleware.run(generateBackupRootSaga)
sagaMiddleware.run(deleteAccountRootSaga)
sagaMiddleware.run(rolesRootSaga)
sagaMiddleware.run(uiTaxIdsRootSaga)
sagaMiddleware.run(BusinessProfileRootSaga)
sagaMiddleware.run(setupPasskeyRootSaga)
sagaMiddleware.run(removePasskeyRootSaga)
sagaMiddleware.run(loginPasskeyRootSaga)
sagaMiddleware.run(removeMFASMSRootSaga)
sagaMiddleware.run(sendMFASMSRootSaga)
sagaMiddleware.run(initiateEmailRootSaga)
sagaMiddleware.run(verifyEmailRootSaga)
sagaMiddleware.run(sendEmailRootSaga)
sagaMiddleware.run(removeEmailRootSaga)
sagaMiddleware.run(initiatePushRootSaga)
sagaMiddleware.run(verifyPushRootSaga)
sagaMiddleware.run(loginPushRootSaga)
sagaMiddleware.run(removePushRootSaga)
sagaMiddleware.run(removePendingPushRootSaga)
sagaMiddleware.run(deviceBackupsRootSaga)
sagaMiddleware.run(assuranceLevelRootSaga)
sagaMiddleware.run(devicesRootSaga)
sagaMiddleware.run(addressesRootSaga)
sagaMiddleware.run(openTicketsRootSaga)
sagaMiddleware.run(closedTicketsRootSaga)
sagaMiddleware.run(ccdTicketsRootSaga)
sagaMiddleware.run(changeEmailRootSaga)
sagaMiddleware.run(trustedDevicesRootSaga)
sagaMiddleware.run(trustDeviceRootSaga)
sagaMiddleware.run(loginIssueFeedbackRootSaga)

window.__RELEASE__ = __RELEASE__

if (!__DEV__) {
  const tagManagerArgs = {
    gtmId: 'GTM-P5VBRWW',
  }

  TagManager.initialize(tagManagerArgs)

  Sentry.init({
    dsn: 'https://b185b3ad1240a5ed1a7199d1e49e105a@o21767.ingest.sentry.io/4506058886676480',
    tracesSampleRate: 0,
    release: `account@${packageJSON.version}`,
    // TODO: Improve. Now setting on hour, not minutes, seconds or milliseconds so within hour they will interfere
    dist: new Date().setMinutes(0, 0, 0).toString(),
  })
}

const load = () => (
  <Provider store={store}>
    <MotifProvider>
      <GlobalStyles />
      <MediaSizingProvider>
        <LocaleProvider>
          <AppIntlProvider>
            <GlobalErrorBoundry>
              <ReCaptchaProviderConnected>
                <BackupsProvider>
                  <QueryClientProvider client={queryClient}>
                    <App />
                  </QueryClientProvider>
                </BackupsProvider>
              </ReCaptchaProviderConnected>
            </GlobalErrorBoundry>
          </AppIntlProvider>
        </LocaleProvider>
      </MediaSizingProvider>
    </MotifProvider>
  </Provider>
)

const root = createRoot(document.getElementById('root')!)
root.render(load())
