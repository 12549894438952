import aws from 'api/aws'
import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { Dispatch } from 'redux'
import { createSelector } from 'reselect'

import createDataModule from 'utils/moduleCreator'
import { closeVisibleModal } from 'modules/modals'
import { UcoreDevice } from 'features/devices/types'
import { selectOwnedUcoreDevices } from 'features/devices/devices'

import {
  Backups,
  DeviceBackups,
  DeviceBackupsArchived,
  DeviceBackupsWithShadow,
} from '../types'
import { BackupItem } from '../utils'

const {
  api: { nca },
} = __CONFIG__

const DEFAULT_DEVICES_PER_FETCH = 10 as const

export const dataKey = 'deviceBackups'

const dataModule = createDataModule<Backups>(
  dataKey,
  nca.paths.deviceBackups,
  nca.base
)

export const {
  selectIsFetchLoading: selectFetchBackupsIsLoading,
  selectIsRemoveLoading: selectDeletingBackups,
  selectErrors: selectBackupFetchErrors,
  reducer: fetchBackupsReducer,
} = dataModule

const selectDeviceBackups = createSelector(
  dataModule.selectData,
  (deviceBackups): Backups => {
    const { backups } = deviceBackups
    if (backups) {
      backups.forEach((backup) => {
        if (!backup.name) {
          backup.name = 'Unknown'
        }
        const timeStampArray = backup.backups.map((detail) => detail.time)
        backup.latestBackupTimestamp = timeStampArray.length
          ? Math.max(...timeStampArray)
          : 0
      })
    }
    return deviceBackups
  }
)

export const selectDevicesBackups = createSelector(
  selectDeviceBackups,
  selectOwnedUcoreDevices,
  (
    { backups = [] }: Backups,
    devices: UcoreDevice[]
  ): DeviceBackupsWithShadow[] =>
    backups.reduce((result: DeviceBackupsWithShadow[], item: DeviceBackups) => {
      const resultCopy = result.slice()
      devices?.forEach((device: UcoreDevice) => {
        if (device.hardwareId === item.hardwareId && !item.archived) {
          resultCopy.push({ ...item, shadow: device.shadow, archived: false })
        }
      })
      return resultCopy
    }, [])
)

export const selectArchievedDeviceBackups = createSelector(
  selectDeviceBackups,
  ({ backups = [] }: Backups): DeviceBackupsArchived[] =>
    backups
      .filter(({ archived }) => archived)
      .reduce(
        (accumulator: DeviceBackupsArchived[], currentValue: DeviceBackups) => {
          const existingObject = accumulator.find(
            (obj) => obj.hardwareId === currentValue.hardwareId
          )
          /*
        BE send duplicate deleted devices every time you adapt the device and delete it again
        but at least they all have the same hardwareId so we match them by that and we flat them to one object
        with adding the different backups to that object, so user don't see the the device twice but rather
        see one device with all of the backups that belongs to that device
        */
          if (existingObject) {
            existingObject.backups = [
              ...existingObject.backups,
              ...currentValue.backups,
            ]
          } else {
            accumulator.push({ ...currentValue, archived: true })
          }

          return accumulator
        },
        []
      )
)

const fetchBackups = async (deviceIds: string) => {
  const response = await aws
    .fetch(
      `${nca.paths.deviceBackups}?deviceIds=${deviceIds}&sort=-filename&includeArchivedBackup=false`,
      {
        method: 'GET',
      }
    )
    .then((res) => res.json())

  return {
    ...response,
    backups: [...response.backups],
  }
}

const fetchArchivedBackups = async () => {
  const responseArchived = await aws
    .fetch(nca.paths.archivedDeviceBackups, {
      method: 'GET',
    })
    .then((res) => res.json())
  const backupsCopy: DeviceBackups[] = [...responseArchived.backups]

  const archivedBackups = backupsCopy.map((backup: DeviceBackups) => ({
    ...backup,
    archived: true,
  }))

  return archivedBackups
}

const triggerFetch = async (devices: UcoreDevice[]) => {
  const deviceIds = devices.map((device: UcoreDevice) => device.id).join(',')
  return await fetchBackups(deviceIds)
}

export const fetchDeviceBackups =
  (allDevices: UcoreDevice[]) => async (dispatch: Dispatch) => {
    dispatch(dataModule.setIsFetchLoading())
    let data: Backups = { backups: [], nextToken: null }
    let iterationNumber = 0
    try {
      while (data.backups.length < allDevices.length) {
        const devicesList = allDevices.slice(
          iterationNumber,
          iterationNumber + DEFAULT_DEVICES_PER_FETCH
        )
        const { backups }: Backups = await triggerFetch(devicesList)

        data = {
          backups: [...data.backups, ...backups],
          nextToken: null,
        }
        iterationNumber += DEFAULT_DEVICES_PER_FETCH
      }
      // Archived backups should be outside the loop since we do not paginate them currently so
      // we don't want to fetch the same archived backups on every iteration of the wile loop
      const archivedBackups = await fetchArchivedBackups()
      data.backups.push(...archivedBackups)
      dispatch({ type: dataModule.FETCH_DONE, payload: data })
    } catch (error) {
      dispatch({ type: dataModule.FETCH_FAILED, payload: { error } })
      return
    }
  }

export const deleteBackups =
  (backups: BackupItem[], devices: UcoreDevice[]) =>
  async (dispatch: Dispatch) => {
    dispatch(dataModule.setIsRemoveLoading(true))
    await Promise.all([
      ...backups.map(
        async (backup) =>
          await aws.fetch(
            `${nca.paths.backup}/${backup.deviceId}/${encodeURIComponent(
              backup.key
            )}`,
            {
              method: 'DELETE',
            }
          )
      ),
    ])

    let updatedDeviceBackupsState: Backups = { backups: [], nextToken: null }
    try {
      const { backups }: Backups = await triggerFetch(devices)
      const archivedBackups = await fetchArchivedBackups()
      return (updatedDeviceBackupsState = {
        backups: [...backups, ...archivedBackups],
        nextToken: null,
      })
    } catch (error) {
      dispatch({ type: dataModule.FETCH_FAILED, payload: { error } })
    } finally {
      dispatch({
        type: dataModule.REMOVE_DONE,
        payload: updatedDeviceBackupsState,
      })
    }
  }

function* deleteBackupsSaga() {
  yield put(closeVisibleModal())
}

function* subscribeToDeleteBackupsSaga() {
  yield takeEvery([dataModule.REMOVE_DONE], deleteBackupsSaga)
}

export function* deviceBackupsRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToDeleteBackupsSaga)])
}
