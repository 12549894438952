import React from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { InfoIcon } from '@ubnt/icons'
import { Button, SkeletonLoader, Tooltip } from '@ubnt/ui-components'
import { typography } from '@ubnt/ui-components/styles/designToken'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import { RoundFlag } from 'components/RoundFlag'
import styled from 'theme/styled'
import { customerHasTaxIdAcceptedCountries } from 'pages/payments/utils'
import { setVisibleModal } from 'modules/modals'
import { InfoContainer } from 'components/SharedComponents'
import { Section, SectionHeader } from 'components/Section.styles'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import RemoveButton from './RemoveButton'
import WrappedTaxIdModal, { TAX_ID_MODAL_ID } from './TaxIdModal'
import { useBillingTaxIdQuery } from 'store/queries/useBillingTaxIdQuery'

const TaxId = () => {
  const dispatch = useDispatch()
  const { cards } = useBillingCardsQuery()
  const { taxId, isTaxIdLoading } = useBillingTaxIdQuery()

  if (cards && !customerHasTaxIdAcceptedCountries(cards)) return null

  return (
    <Section $omitMarginBottom $omitMarginTop>
      <Wrapper>
        <SectionHeader>
          <FormattedMessage id="COMMON_SIDE_NAV_PAYMENTS_TAX_ID" />
          <Tooltip
            position="topLeft"
            width={286}
            description={
              <FormattedMessage id="COMMON_SIDE_NAV_PAYMENTS_TAX_ID_TOOLTIP" />
            }
          >
            <StyledInfoIcon size="original" variant="outline" isActive />
          </Tooltip>
        </SectionHeader>
        {isTaxIdLoading ? (
          <SkeletonLoader>
            <Block height="20px" width="150px" />
          </SkeletonLoader>
        ) : (
          <LeftAlignedColumns>
            <>
              {taxId?.value && (
                <TaxInfo>
                  {taxId?.country && (
                    <FlagContainer>
                      <RoundFlag countryCode={taxId.country.toLowerCase()} />
                    </FlagContainer>
                  )}
                  <CountryText>{taxId?.country}</CountryText>
                  <div>{taxId?.value}</div>
                </TaxInfo>
              )}
              <TaxActions>
                <StyledButton
                  variant="inline"
                  onClick={() => dispatch(setVisibleModal(TAX_ID_MODAL_ID))}
                >
                  <FormattedMessage
                    id={
                      taxId?.value
                        ? 'SETTINGS_PAYMENTS_TAX_ID_CHANGE'
                        : 'SETTINGS_PAYMENTS_TAX_ID_ADD'
                    }
                  />
                </StyledButton>
                {taxId?.value && <RemoveButton />}
              </TaxActions>
            </>
          </LeftAlignedColumns>
        )}
        {taxId?.value && (
          <StyledInfoContainer>
            <InfoIcon variant="fill" isActive size="original" />
            <div>
              <div>
                <FormattedMessage id="SETTINGS_PAYMENTS_TAX_ID_INFO_1" />
              </div>
              <div>
                <FormattedMessage id="SETTINGS_PAYMENTS_TAX_ID_INFO_2" />
              </div>
            </div>
          </StyledInfoContainer>
        )}
        <WrappedTaxIdModal />
      </Wrapper>
    </Section>
  )
}

export default TaxId

const StyledInfoIcon = styled(InfoIcon)`
  margin: -3px 0 0 3px;
`

const LeftAlignedColumns = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 16px;
  gap: 16px;
  width: auto;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
`

const TaxInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  width: auto;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    width: 100%;
  }
`

const TaxActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    justify-content: space-between;
    width: 100%;
  }
`

const StyledButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    margin-left: 30px;
  }
`

const CountryText = styled.div`
  margin-left: -8px;
`

const StyledInfoContainer = styled(InfoContainer)`
  margin-top: 16px;
`

const FlagContainer = styled.div`
  margin-left: 10px;
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.text2};
  font: ${typography['desktop-body']};
`
