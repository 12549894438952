import {
  CardData,
  CustomerPaymentMethods,
  UpdateCardData,
} from 'features/payment-methods/modules/types'
import {
  deleteRequestWithCookies,
  fetchRequestWithCookies,
  postRequestWithCookies,
} from '../axios'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { BillingCustomerApi } from './billingCustomer'
import { STRIPE_FETCH_LIMIT } from '../constants'

const {
  api: { billing },
} = __CONFIG__

type CreateBillingCardArgs = {
  cardData: { source: string; region: StripeRegionCode }
  hasCustomer: boolean
}

const billingCustomerApi = new BillingCustomerApi()

export class BillingCardsApi {
  getAll = async () => {
    const url = new URL(`${billing.paths.paymentMethods}`, billing.base)
    url.searchParams.append('type', 'card')
    url.searchParams.append('limit', STRIPE_FETCH_LIMIT)
    const { data } = await fetchRequestWithCookies<CustomerPaymentMethods>(
      url.href
    )
    return data
  }

  create = async (data: CreateBillingCardArgs) => {
    if (!data.hasCustomer) {
      await billingCustomerApi.create(data.cardData.region)
    }
    const url = new URL(`${billing.paths.cards}`, billing.base)
    url.searchParams.append('region', data.cardData.region)
    await postRequestWithCookies(url.href, data.cardData)
  }

  update = async (data: UpdateCardData) => {
    const url = new URL(`${billing.paths.cards}/${data.id}`, billing.base)
    url.searchParams.append('region', data.region)

    await postRequestWithCookies(url.href, {
      owner: data.owner,
      card: data.card,
    })
  }

  delete = async (data: CardData) => {
    const url = new URL(`${billing.paths.cards}/${data.id}`, billing.base)
    url.searchParams.append('region', data.region)

    await deleteRequestWithCookies(url.href)
  }
}
