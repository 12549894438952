import { useSelector } from 'react-redux'
import { omit, reduce } from 'lodash-es'

import { getRequester } from 'features/support/unifi/utils/requestUtils'
import { selectProfileData } from 'modules/profile'
import {
  FIELD_FALLBACK_VALUE_NA,
  ZENDESK_FOLLOW_UP_REQUEST_TAG,
  ZENDESK_REQUEST_TAG,
} from 'features/support/constants'
import { UcoreDevice } from 'features/devices/types'
import { ZDCustomFields } from 'features/support/types'

import { CustomFields, SupportFormState } from '../types'
import { getTotalNumberOfDevices } from '../../unifi/utils/getTotalNumberOfDevices'
import { FormConfig } from '../config/types'

const { ZENDESK_UNIFI_FORM_KEY } = __CONFIG__

export const useFormatTicket = (config: FormConfig) => {
  const profile = useSelector(selectProfileData)

  const formatRequestedTicket = async (
    values: SupportFormState,
    selectedConsole?: UcoreDevice,
    sendsafelyAttachment?: string,
    followup_source_id?: string
  ) => {
    if (!profile) return
    const requester = getRequester(profile)

    const totalNumberOfDevices = await getTotalNumberOfDevices()

    const shadow = selectedConsole?.shadow.state.reported

    const consoleData = {
      version: shadow?.version,
      fwVersion: shadow?.hardware.firmwareVersion,
      releaseChannel: shadow?.releaseChannel,
    }

    const initialValues: CustomFields = {
      billingSpecificService: values.billingSpecificService,
      console: values.console?.id,
      userType: values.userType,
      attachments: sendsafelyAttachment,
      totalDevices: totalNumberOfDevices,
      mac: values.console?.mac,
      ...consoleData,
      rawData: JSON.stringify(
        {
          billingSpecificService: values.billingSpecificService,
          console: values.console?.id,
          userType: values.userType,
          attachments: sendsafelyAttachment,
          totalDevices: totalNumberOfDevices,
          mac: values.console?.mac,
          ...consoleData,
        },
        null,
        2
      ),
    }

    const ticketFields = omit(config, 'description')

    const custom_fields = reduce<typeof ticketFields, ZDCustomFields>(
      ticketFields,
      (result, field, key) => {
        const id: number = field.fieldId
        const value = initialValues[key as keyof CustomFields]
        if (!value) return result
        return [
          ...result,
          {
            id,
            value,
          },
        ]
      },
      []
    )

    return {
      followup_source_id: followup_source_id || FIELD_FALLBACK_VALUE_NA,
      ticket_form_id: ZENDESK_UNIFI_FORM_KEY,
      requester,
      tags: [
        ZENDESK_REQUEST_TAG,
        ...(followup_source_id ? [ZENDESK_FOLLOW_UP_REQUEST_TAG] : []),
      ],
      subject: '',
      comment: { body: values.description },
      custom_fields,
    }
  }

  return { formatRequestedTicket }
}
