import {
  all,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { api } from 'api'

import { fetchMFA, selectMFAAuthenticators } from 'modules/mfa'
import { MFAAuthenticator, MFAType } from 'modules/types'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { MFAAuthenticatorStatus } from 'enums'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'

import { PASSKEY_FAILED_MODAL_ID } from '../FailedModal'
import { PASSKEY_LOADING_MODAL_ID } from '../LoadingModal'
import {
  transformCredentialCreateOptions,
  transformNewAssertionForServer,
} from '../utils/transformCredentials'
import { removeMFAPasskey } from './removePasskey'
import { passkeyAdded } from 'modules/mfaAdded'
import { checkAuth } from 'features/auth/modules/auth'

const {
  api: { sso },
} = __CONFIG__

export const initiatePasskeyDataKey = 'MFAInitiatePasskey'
export const verifyPasskeyDataKey = 'MFAVerifyPasskey'

const passkeyActions = {
  createInitiate: `${initiatePasskeyDataKey}/CREATE`,
  createVerify: `${verifyPasskeyDataKey}/CREATE`,
}

type InitiatePasskeyState =
  | {
      publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions
      status: MFAAuthenticatorStatus
      last_success: string | null
      id: string
      created: string
      type: MFAType.webAuthn
    }
  | Record<string, never>

type VerifyPasskeyState =
  | {
      PublicKeyCredential: PublicKeyCredential
    }
  | Record<string, never>

const dataModuleInitiate = createDataModule<InitiatePasskeyState, SSOAPIError>(
  initiatePasskeyDataKey,
  `${sso.paths.mfa}/webauthn`,
  api.ssoBase
)

export const { selectErrors: selectInitiatePasskeyErrors } = dataModuleInitiate

const dataModuleVerify = createDataModule<VerifyPasskeyState, SSOAPIError>(
  verifyPasskeyDataKey,
  `${sso.paths.mfa}/webauthn`,
  api.ssoBase
)

export const initiatePasskey = (name: string) => {
  return {
    type: passkeyActions.createInitiate,
    payload: { name },
  }
}

const verifyPasskey = (id: string, publicKeyCredential: Credential) => ({
  type: passkeyActions.createVerify,
  payload: { publicKeyCredential },
  urlPath: `/${id}/verify`,
})

function* initiatePasskeySaga() {
  yield put(fetchMFA())
  yield put(setVisibleModal(PASSKEY_LOADING_MODAL_ID))

  const { error } = yield race({
    success: take(dataModuleInitiate.CREATE_DONE),
    error: take(dataModuleInitiate.CREATE_FAILED),
  })

  if (error) {
    yield put(setVisibleModal(PASSKEY_FAILED_MODAL_ID))
  } else {
    try {
      const { id, publicKeyCredentialCreationOptions } = yield select(
        dataModuleInitiate.selectData
      )
      if (publicKeyCredentialCreationOptions.user.icon) {
        //TODO: This is a temporary fix, remove when this api sends full secure url
        delete publicKeyCredentialCreationOptions.user.icon
      }
      const credential: PublicKeyCredential & {
        response: AuthenticatorAttestationResponse
      } = yield navigator.credentials.create({
        publicKey: transformCredentialCreateOptions(
          publicKeyCredentialCreationOptions
        ),
      })
      yield put(verifyPasskey(id, transformNewAssertionForServer(credential)))
    } catch (error) {
      const { id } = yield select(dataModuleInitiate.selectData)
      yield put(removeMFAPasskey(id))
      yield put(
        setVisibleModal(PASSKEY_FAILED_MODAL_ID, {
          error,
        })
      )
    }
  }
  yield put(dataModuleInitiate.resetData())
}

function* verifyPasskeySaga() {
  const { error } = yield race({
    success: take(dataModuleVerify.CREATE_DONE),
    error: take(dataModuleVerify.CREATE_FAILED),
  })

  if (error) {
    const { id } = yield select(dataModuleInitiate.selectData)
    yield put(removeMFAPasskey(id))
    yield put(setVisibleModal(PASSKEY_FAILED_MODAL_ID))
  } else {
    const authenticators: MFAAuthenticator[] = yield select(
      selectMFAAuthenticators
    )
    const active = authenticators.filter((auth) => auth.status === 'active')
    if (active.length) {
      yield put(passkeyAdded(false))
    } else {
      yield put(passkeyAdded(true))
    }
    yield put(closeVisibleModal())
  }

  yield put(dataModuleVerify.resetData())
  yield put(fetchMFA())
  yield put(checkAuth())
}

function* subscribeToInitiatePasskeySaga() {
  yield takeEvery(passkeyActions.createInitiate, initiatePasskeySaga)
}

function* subscribeToVerifyPasskeySaga() {
  yield takeEvery(passkeyActions.createVerify, verifyPasskeySaga)
}

export function* setupPasskeyRootSaga() {
  yield all([
    spawn(dataModuleInitiate.rootSaga),
    spawn(dataModuleVerify.rootSaga),
    spawn(subscribeToInitiatePasskeySaga),
    spawn(subscribeToVerifyPasskeySaga),
  ])
}

export const initiatePasskeyReducer = dataModuleInitiate.reducer
export const verifyPasskeyReducer = dataModuleVerify.reducer
