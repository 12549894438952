import { fetchRequestWithCookies } from './axios'

const {
  api: { talk },
} = __CONFIG__

interface Install {
  device_name: string
  device_serial: string
  device_type: string
  id: number
  phone_number_count: number
  phone_number_list: { phone_number: number }[]
  phone_numbers: number
  subscription: string
  time: string
}

export type TalkHeaders =
  | {
      'x-unifi-sso-env': string
    }
  | undefined

export class TalkInstallsApi {
  get = async () => {
    const url = new URL(talk.paths.installs, talk.base)

    let customHeaders: TalkHeaders = undefined

    if (talk.base.includes('.stg.')) {
      customHeaders = {
        'x-unifi-sso-env': 'stg',
      }
    } else if (talk.base.includes('.dev.')) {
      customHeaders = {
        'x-unifi-sso-env': 'dev',
      }
    }
    const { data } = await fetchRequestWithCookies<Install[]>(
      url.href,
      customHeaders
    )
    return data
  }
}
