import { StripeCountry, StripeRegionCode } from '../types'

const {
  featureFlags: { areExtraEuCountriesEnabled },
} = __CONFIG__

const extraEuCountries: Readonly<StripeCountry[]> = [
  {
    code: 'tr',
    name: 'Türkiye',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'tr_tin',
        taxFormat: /\d{10}$/,
        taxPlaceholder: '0123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'ci',
    name: "Côte d'Ivoire",
    region: StripeRegionCode.EU,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'rs',
    name: 'Serbia',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'rs_pib',
        taxFormat: /\d{9}$/,
        taxPlaceholder: '123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'ba',
    name: 'Bosnia and Herzegovina',
    region: StripeRegionCode.EU,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'ge',
    name: 'Georgia',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'ge_vat',
        taxFormat: /\d{9}$/,
        taxPlaceholder: '123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'ad',
    name: 'Andorra',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'ad_nrt',
        taxFormat: /^[A-Z]{1}-\d{6}-[A-Z]{1}$/,
        taxPlaceholder: 'A-123456-Z',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'gl',
    name: 'Greenland',
    region: StripeRegionCode.EU,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'mc',
    name: 'Monaco',
    region: StripeRegionCode.EU,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'fo',
    name: 'Faroe Islands',
    region: StripeRegionCode.EU,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'ax',
    name: 'Aland Islands',
    region: StripeRegionCode.EU,
    taxId: [] as StripeCountry['taxId'],
  },
]

export const euStripeCountries: Readonly<StripeCountry[]> = [
  {
    code: 'at',
    name: 'Austria',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^ATU\d{8}$/,
        taxPlaceholder: 'ATU12345678',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'be',
    name: 'Belgium',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^BE\d{10}$/,
        taxPlaceholder: 'BE0123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'bg',
    name: 'Bulgaria',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^BG\d{9,10}/,
        taxPlaceholder: 'BG0123456789',
      },
      {
        value: 'bg_uic',
        taxFormat: /^\d{9}$/,
        taxPlaceholder: '123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'hr',
    name: 'Croatia',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^HR\d{11}$/,
        taxPlaceholder: 'HR12345678912',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'cy',
    name: 'Cyprus',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^CY[a-zA-Z0-9]{9}$/,
        taxPlaceholder: 'CY12345678Z',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'cz',
    name: 'Czech Republic',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^CZ\d{8,10}$/,
        taxPlaceholder: 'CZ1234567890',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'dk',
    name: 'Denmark',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^DK\d{8}$/,
        taxPlaceholder: 'DK12345678',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'ee',
    name: 'Estonia',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^EE\d{9}$/,
        taxPlaceholder: 'EE123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'fi',
    name: 'Finland',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^FI\d{8}$/,
        taxPlaceholder: 'FI12345678',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'fr',
    name: 'France',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^FR[a-zA-Z0-9]{2}\d{9}$/,
        taxPlaceholder: 'FRAB123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'de',
    name: 'Germany',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^DE\d{9}$/,
        taxPlaceholder: 'DE123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'gr',
    name: 'Greece',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^EL\d{9}$/,
        taxPlaceholder: 'EL123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'hu',
    name: 'Hungary',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^HU\d{8}$/,
        taxPlaceholder: 'HU12345678',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'is',
    name: 'Iceland',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'is_vat',
        taxFormat: /^\d{6}$/,
        taxPlaceholder: '123456',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'ie',
    name: 'Ireland',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^IE\d{7}[A-Z]{1,2}$|^IE\d[A-Z]\d{5}[A-Z]$/,
        taxPlaceholder: 'IE1234567AB',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'it',
    name: 'Italy',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^IT\d{11}$/,
        taxPlaceholder: 'IT12345678912',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'lv',
    name: 'Latvia',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^LV\d{11}$/,
        taxPlaceholder: 'LV12345678912',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'li',
    name: 'Liechtenstein',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'li_uid',
        taxFormat: /^CHE\d{9}$/,
        taxPlaceholder: 'CHE123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'lt',
    name: 'Lithuania',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^LT\d{9,12}$/,
        taxPlaceholder: 'LT123456789123',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'lu',
    name: 'Luxembourg',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^LU\d{8}$/,
        taxPlaceholder: 'LU12345678',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'mt',
    name: 'Malta',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^MT\d{8}$/,
        taxPlaceholder: 'MT12345678',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'nl',
    name: 'Netherlands',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^NL\d{9}B\d{2}$/,
        taxPlaceholder: 'NL123456789B12',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'no',
    name: 'Norway',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'no_vat',
        // optional NO, 9 digits, optional MVA src: https://vatstack.com/articles/norway-vat-number-validation
        taxFormat: /^(NO)?\d{9}(MVA)?$/,
        taxPlaceholder: '123456789MVA',
      },
    ] as StripeCountry['taxId'],
  },

  {
    code: 'pl',
    name: 'Poland',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^PL\d{10}$/,
        taxPlaceholder: 'PL1234567890',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'pt',
    name: 'Portugal',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^PT\d{9}$/,
        taxPlaceholder: 'PT123456789',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'ro',
    name: 'Romania',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^RO\d{2,10}$/,
        taxPlaceholder: 'RO1234567891',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'sk',
    name: 'Slovakia',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^SK\d{10}$/,
        taxPlaceholder: 'SK1234567891',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'si',
    name: 'Slovenia',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^SI\d{8}$/,
        taxPlaceholder: 'SI12345678',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'es',
    name: 'Spain',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^ES[a-zA-Z0-9]{9}$/,
        taxPlaceholder: 'ESA1234567Z',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'se',
    name: 'Sweden',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'eu_vat',
        taxFormat: /^SE\d{12}$/,
        taxPlaceholder: 'SE123456789123',
      },
    ] as StripeCountry['taxId'],
  },
  {
    code: 'ch',
    name: 'Switzerland',
    region: StripeRegionCode.EU,
    taxId: [
      {
        value: 'ch_vat',
        taxFormat: /^CHE-\d{3}.\d{3}.\d{3}\s(MWST|TVA|IVA)$/,
        taxPlaceholder: 'CHE-123.456.789 MWST',
      },
    ] as StripeCountry['taxId'],
  },
  ...(areExtraEuCountriesEnabled ? extraEuCountries : []),
]
