import { deleteRequestWithCookies, fetchRequestWithCookies } from './axios'

const {
  api: { sso, ssoUi },
} = __CONFIG__

export interface Session {
  session_id: string
  user_agent: string | null
  device_model: string | null
  is_current_session: boolean
  is_trusted_device_session: boolean
  location: {
    city: string | null
    state: string | null
    country: string | null
    countryCode: string | null
  } | null
  ip: string | null
  isp: string | null
  device_id: string | null
  device_name: string | null
  extra_fields: {
    referer?: string | null
  }
  created: string
  simplified_agent: {
    agent: string | null
    is_ui_product: boolean
    os: string
    console?: {
      name?: string
      type?: string
      product_code?: string
    } | null
  }
}

export class SessionsApi {
  getAll = async () => {
    const url = new URL(sso.paths.sessions, ssoUi.base)
    const { data } = await fetchRequestWithCookies<Session[]>(url.href)
    return data
  }

  revokeNonCurrent = async () => {
    const url = new URL(sso.paths.sessions, ssoUi.base)
    await deleteRequestWithCookies(url.href)
  }

  revokeOne = async (sessionId: string) => {
    const url = new URL(`${sso.paths.sessions}/${sessionId}`, ssoUi.base)
    await deleteRequestWithCookies(url.href)
  }
}
