import React, { useContext, useMemo, useState } from 'react'
import { RoundFlag } from 'components/RoundFlag'
import { CANCEL_SUBSCRIPTION_EXT_UISP_CLOUD_CONSOLE_MODAL_ID } from 'pages/subscriptions/ExternalCancelSubscriptionUispCloudConsoleModal'
import type Stripe from 'stripe'
import { useIntl } from 'react-intl'
import { SubscriptionStatus } from 'features/subscriptions/types'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import {
  CardCell,
  Cell,
  CellColumns,
  CellContainer,
  ChargeCell,
  Row,
  RowWarningIcon,
} from '../Styles'
import { DateCell } from '../../cells/DateCell'
import { getSubscriptionBillingDate } from '../../utils'
import {
  SubscriptionCard,
  SubscriptionCharge,
  SubscriptionMorePopover,
} from '../components'
import { getPlan } from './utils'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'

type SubscriptionItemType = {
  key: number
  item?: Stripe.SubscriptionItem
  mixedRegions?: boolean
  fullSubscription: FullSubscription
  cardId: number
}

export const SubscriptionItem: React.FC<SubscriptionItemType> = ({
  key,
  item,
  mixedRegions,
  fullSubscription,
  cardId,
}) => {
  const { region, status, cancelAtPeriodEnd } = fullSubscription
  const { isMaxXSmall } = useContext(MediaSizingContext)
  const intl = useIntl()

  const [cardPopoverOpen, setCardPopoverOpen] = useState<number | null>(null)
  const [morePopoverOpen, setMorePopoverOpen] = useState<number | null>(null)

  const hidePopoverToggle = useMemo(() => {
    return status === SubscriptionStatus.CANCELED && !isMaxXSmall
  }, [isMaxXSmall, status])

  return (
    <Row key={key}>
      <CellColumns
        $mixedRegions={mixedRegions}
        $cancelAtPeriodEnd={cancelAtPeriodEnd}
      >
        {cancelAtPeriodEnd && (
          <CellContainer>
            <RowWarningIcon width={20} height={20} variant="fill" />
          </CellContainer>
        )}
        {mixedRegions && (
          <CellContainer>
            <Cell>
              <RoundFlag countryCode={region} noMargin />
            </Cell>
          </CellContainer>
        )}
        <CellContainer>
          <Cell>{getPlan(item || fullSubscription)}</Cell>
        </CellContainer>
        {!isMaxXSmall && (
          <>
            <CellContainer>
              <Cell>{item?.quantity || fullSubscription.quantity || 1}</Cell>
            </CellContainer>
            <CellContainer>
              <Cell>
                <DateCell dt={getSubscriptionBillingDate(fullSubscription)} />
              </Cell>
            </CellContainer>
            <CellContainer>
              <CardCell>
                <SubscriptionCard
                  fullSubscription={fullSubscription}
                  cardPopoverOpen={cardPopoverOpen}
                  setCardPopoverOpen={setCardPopoverOpen}
                  id={cardId}
                />
              </CardCell>
            </CellContainer>
          </>
        )}
        <CellContainer>
          <ChargeCell>
            {cancelAtPeriodEnd ? (
              intl.formatMessage({
                id: 'SETTINGS_SUBSCRIPTIONS_CANCELED_AT_MONTH_END_ROW',
              })
            ) : (
              <SubscriptionCharge fullSubscription={fullSubscription} />
            )}
          </ChargeCell>
        </CellContainer>
        <CellContainer>
          {!hidePopoverToggle && (
            <SubscriptionMorePopover
              fullSubscription={fullSubscription}
              morePopoverOpen={morePopoverOpen}
              setCardPopoverOpen={setCardPopoverOpen}
              setMorePopoverOpen={setMorePopoverOpen}
              cardPopoverOpen={cardPopoverOpen}
              modalId={CANCEL_SUBSCRIPTION_EXT_UISP_CLOUD_CONSOLE_MODAL_ID}
              id={cardId}
            />
          )}
        </CellContainer>
      </CellColumns>
    </Row>
  )
}
