import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { typography } from '@ubnt/ui-components/styles/designToken'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'
import { setVisibleModal } from 'modules/modals'
import { selectIsTwoFactorAuthEnabled } from 'modules/profile'
import { extendAssuranceLevel } from 'features/auth/modules/assuranceLevel'
import { LockTooltip } from 'pages/security/LockTooltip'
import { selectMFAAuthenticators } from 'modules/mfa'
import { MFAType } from 'modules/types'

import { SETUP_MFA_MODAL_ID } from '../SetupMFAModal'
import { removePendingMFAPush } from '../Push/modules/removePendingPush'
import { removePendingMFATOTP } from '../TOTP/modules/removePendingTOTP'

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: ${(p) => p.theme.media.small}) {
    flex-direction: column;
    align-items: start;
  }
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    padding-right: 0;
    padding-left: 0;
  }
`

const Text = styled.div`
  font-weight: bold;
  font: ${typography['desktop-typography-heading-large']};
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;
`
const Description = styled.div<{ isMFAEnabled?: boolean }>`
  font: ${typography['desktop-typography-body']} !important;
  margin-top: 4px;
  color: ${({ theme }) => theme.text2};
  margin-left: ${({ isMFAEnabled }) => (isMFAEnabled ? '16px' : 0)};
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  max-width: calc(100% - 280px);
  @media (max-width: ${({ theme }) => theme.media.small}) {
    max-width: 100%;
  }
`

const Circle = styled.div<{ isOn?: boolean }>`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: ${({ isOn, theme }) => (isOn ? theme.green06 : '#B6B9C4')};
  border-radius: 50%;
  margin-right: 10px;
`

const ButtonSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.media.small}) {
    flex-direction: column-reverse;
    width: 100%;
  }
`
const ButtonWrapper = styled.div`
  margin-left: 8px;
  @media (max-width: ${({ theme }) => theme.media.small}) {
    margin-left: 0;
    margin-bottom: 16px;
    width: max-content;
  }
`

export const MultiFactorHeader: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isMFAEnabled = useSelector(selectIsTwoFactorAuthEnabled)
  const authenticators = useSelector(selectMFAAuthenticators)

  const removePendingAuthenticators = useCallback(() => {
    authenticators.forEach((authenticator) => {
      if (
        authenticator.type === MFAType.totp &&
        authenticator.status === 'pending'
      ) {
        dispatch(removePendingMFATOTP(authenticator.id))
      }
      if (
        authenticator.type === MFAType.push &&
        authenticator.status === 'pending'
      ) {
        dispatch(removePendingMFAPush(authenticator.id))
      }
    })
  }, [authenticators, dispatch])

  return (
    <Wrapper>
      <TextWrapper>
        <Text>
          {isMFAEnabled && <Circle isOn />}
          {intl.formatMessage({ id: 'SETTINGS_MFA_HEADER_TITLE' })}
        </Text>
        <Description isMFAEnabled={isMFAEnabled}>
          {intl.formatMessage({ id: 'SETTINGS_MFA_HEADER_DESCRIPTION' })}
          {intl.formatMessage({ id: 'SETTINGS_MFA_HEADER_EXTRA_DESCRIPTION' })}
        </Description>
      </TextWrapper>
      <ButtonSectionWrapper>
        <LockTooltip inline={true}>
          <ButtonWrapper>
            <Button
              variant="secondary"
              onClick={() => {
                removePendingAuthenticators()
                dispatch(extendAssuranceLevel())
                dispatch(setVisibleModal(SETUP_MFA_MODAL_ID))
              }}
            >
              {intl.formatMessage({
                id: isMFAEnabled
                  ? 'SETTINGS_MFA_ADD_BACKUP_ACTION'
                  : 'COMMON_ACTION_ENABLE',
              })}
            </Button>
          </ButtonWrapper>
        </LockTooltip>
      </ButtonSectionWrapper>
    </Wrapper>
  )
}
