import { Dispatch } from 'redux'
import { createSelector } from 'reselect'
import axios from 'axios'
import createDataModule from 'utils/moduleCreator'
import { ZendeskTicket, ZendeskTicketsResponse } from 'pages/requests/types'

const {
  api: { accountBE },
} = __CONFIG__

export const closedTicketsDataKey = 'zendeskClosedTickets'

const dataModule = createDataModule<ZendeskTicketsResponse>(
  closedTicketsDataKey,
  accountBE.paths.zendeskClosedTickets,
  accountBE.base
)

export const {
  fetch: fetchClosedTickets,
  selectIsDone: selectClosedTicketsFetched,
  selectErrors: selectClosedTicketsErrors,
  selectIsLoading: selectClosedTicketsAreLoading,
  rootSaga: closedTicketsRootSaga,
  reducer: closedTicketsReducer,
} = dataModule

export const selectClosedTickets = createSelector(
  dataModule.selectData,
  ({ tickets }: ZendeskTicketsResponse): ZendeskTicket[] => tickets ?? []
)

export const selectHasMoreClosedTickets = createSelector(
  dataModule.selectData,
  ({ tickets, totalTickets }: ZendeskTicketsResponse): boolean =>
    tickets?.length < totalTickets
)

export const selectTotalClosedTickets = createSelector(
  dataModule.selectData,
  ({ totalTickets }: ZendeskTicketsResponse): number => totalTickets
)

export const fetchExtraClosedTickets =
  (page: number, fetchedTickets: ZendeskTicket[]) =>
  async (dispatch: Dispatch) => {
    dispatch(dataModule.setIsFetchLoading())
    const pathWithParams = `${accountBE.base}/${accountBE.paths.zendeskClosedTickets}?page=${page}`
    try {
      const response = await axios.get(pathWithParams, {
        withCredentials: true,
      })
      const tickets = [
        ...(fetchedTickets ?? []),
        ...(response?.data?.tickets ?? []),
      ]
      const { totalTickets } = response.data
      dispatch({
        type: dataModule.FETCH_DONE,
        payload: { tickets, totalTickets },
      })
    } catch (e) {
      dispatch({ type: dataModule.FETCH_FAILED, payload: { error: e } })
    }
  }
