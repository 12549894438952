import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ClassNames } from '@emotion/react'
import { Tooltip } from '@ubnt/ui-components'

import { MFAAuthenticatorStatus } from 'enums'
import { MFAAuthenticator, MFAType } from 'modules/types'
import { updateProfileData } from 'modules/profile'
import { extendAssuranceLevel } from 'features/auth/modules/assuranceLevel'
import { LockTooltip } from 'pages/security/LockTooltip'

import { prepareData } from './prepareData'
import {
  Badge,
  DeactivatedBadge,
  Description,
  EditSelector,
  IconTypeWrapper,
  Info,
  InfoContainer,
  InnerWrapper,
  Option,
  PendingBadge,
  StyledButton,
  StyledImg,
  TitleWrapper,
  Type,
  Wrapper,
} from './styled'
import { MFARemoveButton } from './MFARemoveButton'

interface Props {
  authenticator: MFAAuthenticator
  isDeleteDisabled: boolean
  isPrimary: boolean
  iconBorder: boolean
  isPrimaryTOTP?: boolean
}

export const MultiFactorMethod: React.FC<Props> = ({
  authenticator,
  isDeleteDisabled,
  isPrimary,
  iconBorder = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()

  const preparedData = prepareData(authenticator, intl, dispatch)

  if (!preparedData) {
    return null
  }
  const { iconLink, typeText, description, action, info, type, icon } =
    preparedData

  const isPending = authenticator.status === MFAAuthenticatorStatus.PENDING
  const isDeactivated =
    authenticator.status === MFAAuthenticatorStatus.DEACTIVATED

  const setPrimary = () => {
    const data = {
      default_mfa: authenticator.id,
    }
    dispatch(updateProfileData(data))
  }

  const dontShowEdit = [MFAType.push, MFAType.sms, MFAType.email]

  return (
    <Wrapper id="popoverRoot">
      <InnerWrapper>
        <IconTypeWrapper iconBorder={iconBorder}>
          {iconLink && (
            <StyledImg
              src={iconLink}
              alt={typeText}
              size={authenticator.type === MFAType.push ? '36px' : undefined}
            />
          )}
          {icon && icon}
        </IconTypeWrapper>
        <div>
          <TitleWrapper>
            <Type>{typeText}</Type>
            {isPrimary && (
              <Badge>
                {intl.formatMessage({
                  id: 'SETTINGS_MFA_METHOD_PRIMARY_BADGE',
                })}
              </Badge>
            )}
            {isPending && (
              <PendingBadge>
                {intl.formatMessage({
                  id: 'SETTINGS_MFA_METHOD_PENDING_BADGE',
                })}
              </PendingBadge>
            )}
            {isDeactivated && (
              <Tooltip
                description={intl.formatMessage({
                  id: 'SETTINGS_MFA_METHOD_DEACTIVATED_TOOLTIP',
                })}
                width={300}
                position="bottom"
              >
                <DeactivatedBadge>
                  {intl.formatMessage({
                    id: 'SETTINGS_MFA_METHOD_DEACTIVATED_BADGE',
                  })}
                </DeactivatedBadge>
              </Tooltip>
            )}
          </TitleWrapper>
          <InfoContainer>
            {description && <Description>{description}</Description>}
            {!isPending && <Info>{info}</Info>}
          </InfoContainer>
        </div>
      </InnerWrapper>
      <InnerWrapper>
        <ClassNames>
          {({ css }) => (
            <LockTooltip inline={true} position="left">
              <EditSelector
                open={isOpen}
                onChange={() => {
                  setIsOpen(!isOpen)
                }}
                className="editSelector"
                renderToggle={() => (
                  <>
                    {!isDeleteDisabled &&
                      !(
                        dontShowEdit.includes(authenticator.type) && isPrimary
                      ) && (
                        <StyledButton variant="tertiary">
                          <FormattedMessage id="COMMON_ACTION_EDIT" />
                        </StyledButton>
                      )}
                  </>
                )}
                classNameCard={css`
                  width: 200px;
                  margin-top: 5px;
                  padding: 0;
                `}
                rootSelector="popoverRoot"
                align="bottomLeft"
                toggleOffset={0}
              >
                {!(isPrimary || isPending || isDeactivated) && (
                  <Option
                    onClick={() => {
                      setPrimary()
                      setIsOpen(false)
                    }}
                  >
                    {intl.formatMessage({
                      id: 'SETTINGS_MFA_SET_PRIMARY_METHOD',
                    })}
                  </Option>
                )}
                {action.verify && (
                  <Option
                    onClick={() => {
                      dispatch(extendAssuranceLevel())
                      action.verify!.action()
                    }}
                  >
                    {action.verify.text}
                  </Option>
                )}
                {authenticator.status ===
                  MFAAuthenticatorStatus.DEACTIVATED && (
                  <Option
                    onClick={() => {
                      dispatch(extendAssuranceLevel())
                      action.setup?.action()
                      setIsOpen(false)
                    }}
                  >
                    {intl.formatMessage({ id: 'COMMON_ACTION_SETUP' })}
                  </Option>
                )}
                {!(authenticator.status === 'active' && isDeleteDisabled) && (
                  <MFARemoveButton
                    onClick={() => {
                      dispatch(extendAssuranceLevel())
                      action.remove.action()
                      setIsOpen(false)
                    }}
                    type={type}
                    isActive={authenticator.status === 'active'}
                  />
                )}
              </EditSelector>
            </LockTooltip>
          )}
        </ClassNames>
      </InnerWrapper>
    </Wrapper>
  )
}
