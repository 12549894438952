import { Dispatch } from 'redux'
import { all, spawn } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import aws from 'api/aws'
import createDataModule from 'utils/moduleCreator'

import { Device, UcoreDevice } from './types'
import { FALLBACK_SHADOW_DATA } from './fallbackShadowData'

const {
  api: { nca },
} = __CONFIG__

export const devicesDataKey = 'devicesDataKey'

const dataModule = createDataModule<(Device | UcoreDevice)[]>(
  devicesDataKey,
  nca.paths.devices,
  nca.base
)

export const {
  selectData: selectDevices,
  selectHasFetchedOnce,
  reducer: devicesReducer,
  selectIsFetchLoading: selectFetchingDevices,
} = dataModule

const selectUcoreDevices = createSelector(
  selectDevices,
  (devices): UcoreDevice[] => {
    if (!devices || !devices.length) return []
    return devices.filter((device, i): device is UcoreDevice => {
      if (!device?.shadow?.state?.reported?.name) {
        device.shadow = { ...FALLBACK_SHADOW_DATA, deviceId: i.toString() }
      }

      return (device as UcoreDevice).type === 'ucore'
    })
  }
)

export const selectValidUcoreDevices = createSelector(
  selectDevices,
  (devices): UcoreDevice[] => {
    if (!devices || !devices.length) return []
    return devices.filter((device): device is UcoreDevice => {
      if (
        !device?.shadow?.state?.reported?.name ||
        device.shadow.state.reported.version === 'Uncategorized' ||
        device.type !== 'ucore'
      ) {
        // filter out corrupted data, because they will not show up properly
        return false
      }

      return true
    })
  }
)

export const selectOwnedUcoreDevices = createSelector(
  selectUcoreDevices,
  (devices): UcoreDevice[] => {
    if (!devices || !devices.length) return []
    return devices.filter((device) => {
      return device.owner
    })
  }
)

export const fetchDevices =
  (
    lastSeenAfterDate = 1000000000000 // As default fetch all devices seen after random date in 2001
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(dataModule.setIsFetchLoading())
    let devicesList: Device[] | UcoreDevice[] = []
    const pathWithParams = `${nca.paths.devices}?withUserData=true&type=all&lastSeenAfter=${lastSeenAfterDate}&sort=-latestBackupTimestamp&filterShadow=false`
    try {
      devicesList = await aws
        .fetch(pathWithParams, { method: 'GET' })
        .then((res) => res.json())
    } catch (e) {
      dispatch({ type: dataModule.FETCH_FAILED, payload: { error: e } })
      return
    }
    dispatch({ type: dataModule.FETCH_DONE, payload: devicesList })
  }

export function* devicesRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}
