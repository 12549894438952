import React, { useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/Button'
import { BubbleContainer } from '@ubnt/ui-components/BubbleContainer'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import PublicPage from 'pages/PublicPage'
import styled from 'theme/styled'
import { selectCurrentTrustedDevice } from 'modules/trustedDevices'
import {
  addTrustedDevice,
  fetchDeviceInfo,
  resetErrors,
  selectDeviceInfo,
  selectDeviceInfoLoading,
  selectErrors,
  selectHasFetchedOnce,
} from 'modules/trustDevice'
import {
  getTrustedDeviceImage,
  getTrustedDeviceNameForDevicePage,
} from 'pages/security/components/MultiFactor/utils'
import { selectIsAuthenticated } from '../modules/auth'
import {
  innerRedirect,
  outerRedirect,
  storedInnerRedirectKey,
  storedTrustDeviceRedirectKey,
} from 'features/redirect-after-register'

export const TrustDevice = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentTrustedDevice = useSelector(selectCurrentTrustedDevice)
  const device = useSelector(selectDeviceInfo)
  const isDeviceLoading = useSelector(selectDeviceInfoLoading)
  const hasDeviceInfoFetchedOnce = useSelector(selectHasFetchedOnce)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const deviceApiErrors = useSelector(selectErrors)

  const navigate = useCallback(() => {
    localStorage.removeItem(storedTrustDeviceRedirectKey)
    // Sometimes due to a bug or the cache being cleared we don't get an innerRedirect, so we need
    // to check for it here to make sure the user does not get "stuck" on this page
    const storedInnerRedirectItem = localStorage?.getItem(
      storedInnerRedirectKey
    )
    if (outerRedirect()) {
      return <></>
    }
    if (storedInnerRedirectItem) {
      innerRedirect(history)
    } else {
      history.push('/')
    }
  }, [history])

  useEffect(() => {
    dispatch(fetchDeviceInfo())
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(deviceApiErrors).length) {
      // For now if information on the device cannot be retrieved we skip the trust device step
      navigate()
      dispatch(resetErrors())
    }
  }, [deviceApiErrors, dispatch, navigate])

  const trustDevice = () => {
    dispatch(addTrustedDevice(device.device_id))
  }

  const deviceName = useMemo(() => {
    if (isDeviceLoading || !hasDeviceInfoFetchedOnce) {
      return undefined
    }
    const name = getTrustedDeviceNameForDevicePage({
      model: device.device_model,
      name: device.device_name,
      isUiProduct: !!device.simplified_agent?.is_ui_product,
    })
    const fallbackName = '-'
    const deviceName = name ?? fallbackName
    return deviceName
  }, [
    device.device_model,
    device.device_name,
    device.simplified_agent?.is_ui_product,
    hasDeviceInfoFetchedOnce,
    isDeviceLoading,
  ])

  const deviceImageSrc = useMemo(() => {
    if (deviceName) {
      return getTrustedDeviceImage(deviceName)
    }
    return undefined
  }, [deviceName])

  useEffect(() => {
    if (currentTrustedDevice) {
      navigate()
    }
    if (!isAuthenticated) {
      history.push('/login')
    }
  }, [currentTrustedDevice, history, isAuthenticated, navigate])

  return (
    <PublicPage showLogo={false} formHeight="468px">
      <Wrapper>
        <Wrapper $smallGap>
          <Text variant="heading-large">
            <FormattedMessage id="SETTINGS_ADD_TRUSTED_DEVICE_TITLE" />
          </Text>
          <Text variant="body-primary">
            <FormattedMessage id="SETTINGS_ADD_TRUSTED_DEVICE_DESCRIPTION" />
          </Text>
        </Wrapper>
        <StyledBubbleContainer headerTitle={null} withBackground>
          <Device>
            <ImageContainer>
              {(isDeviceLoading || !hasDeviceInfoFetchedOnce) && (
                <SkeletonLoader>
                  <Block height="36px" width="100%" />
                </SkeletonLoader>
              )}
              {deviceImageSrc && (
                <DeviceImage src={deviceImageSrc} alt="Device" />
              )}
            </ImageContainer>
            <DeviceInfo>
              {(isDeviceLoading || !hasDeviceInfoFetchedOnce) && (
                <SkeletonLoader>
                  <Block height="20px" width="100%" />
                </SkeletonLoader>
              )}
              {deviceName && <Text variant="heading-medium">{deviceName}</Text>}
              <Text variant="caption">
                <FormattedMessage id="SETTINGS_ADD_TRUSTED_DEVICE_MANAGE_INFO" />
              </Text>
            </DeviceInfo>
          </Device>
        </StyledBubbleContainer>
        <Wrapper $smallGap>
          <Button
            variant="primary"
            onClick={trustDevice}
            disabled={!device.device_id || isDeviceLoading}
          >
            <FormattedMessage id="SETTINGS_ADD_TRUSTED_DEVICE_BUTTON" />
          </Button>
          <Button onClick={navigate}>
            <FormattedMessage id="COMMON_ACTION_NOT_NOW" />
          </Button>
        </Wrapper>
      </Wrapper>
    </PublicPage>
  )
}

const Wrapper = styled.div<{ $smallGap?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $smallGap }) => ($smallGap ? '16px' : '32px')};
`

const StyledBubbleContainer = styled(BubbleContainer)`
  padding: 16px 20px;
`

const Device = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 48px;
`

const DeviceImage = styled.img`
  width: 48px;
  object-fit: contain;
`

const DeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
