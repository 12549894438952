export enum QueryKey {
  LEGAL = 'legal',
  SESSIONS = 'sessions',
  ANNOUNCEMENTS = 'announcements',
  BILLING_CUSTOMER = 'billingCustomer',
  BILLING_CARDS = 'billingCards',
  BILLING_TAX_ID = 'billingTaxId',
  BILLING_SUBSCRIPTIONS = 'billingSubscriptions',
  BILLING_PRODUCTS = 'billingProducts',
  BILLING_INVOICES = 'billingInvoices',
  BILLING_INVOICE_LINES = 'billingInvoiceLines',
  BILLING_UPCOMING_INVOICES = 'billingUpcomingInvoices',
  ACTIVITY = 'activity',
  STATUS = 'status',
  STATUS_ACTIVE = 'statusActive',
  STATUS_UPCOMING = 'statusUpcoming',
  STATUS_INCIDENTS = 'statusIncidents',
  UMR_DEVICES = 'umrDevices',
  UMR_DEVICE = 'umrDevice',
  TALK_INSTALLS = 'talkInstalls',
}
