import axios, { AxiosInstance } from 'axios'

import { BackupItem } from 'features/backups/utils'
import { LoginData, LoginMFAData, ProfileData, UserSettings } from 'types/types'
import getQueryVariable from 'utils/getQueryVariable'
import axiosErrorInterceptor from 'utils/axiosErrorInterceptor'
import {
  storedInnerRedirectKey,
  storedOuterRedirectKey,
} from 'features/redirect-after-register'
import { BrowserUtils } from 'utils/browserUtils'

import aws from './aws'

const {
  api: { sso, ssoUi, lte, nca, geo, cap, accountBE, feedback },
} = __CONFIG__

export enum ErrorMessage {
  FILE_TOO_BIG = 'File Too Big',
  BAD_REQUEST = 'Bad Request',
}

enum ErrorCode {
  BACKUP_SIZE_LIMIT = 'BACKUP_FILE_SIZE_LIMIT_EXCEEDED',
}

interface AddressToValidate {
  country: string
  city?: string
  postal_code: string
  region?: string
  street?: string
  street2?: string
}

class Api {
  sso: AxiosInstance
  ssoBase: string
  ssoDoubleBase: string

  constructor() {
    let ssoBase: string
    let ssoDoubleBase: string
    if (window.location.host.match(/\.ui\.com/)) {
      ssoBase = ssoUi.base
      ssoDoubleBase = sso.base
    } else {
      ssoBase = sso.base
      ssoDoubleBase = ssoUi.base
    }
    this.ssoBase = ssoBase
    this.ssoDoubleBase = ssoDoubleBase

    this.sso = axios.create({
      withCredentials: true,
      baseURL: ssoBase,
    })

    axiosErrorInterceptor(this.sso)
  }

  invokeSSOTokenSetup = async (token: string) => {
    const response = await this.sso.post(sso.paths.setupLoginToken, {
      token,
    })
    return response.data
  }

  approveLoginToken = async (token: string) => {
    const response = await this.sso.post(sso.paths.approveLoginToken, {
      token,
    })
    return response
  }

  cancelLoginToken = async (token: string) => {
    const response = await this.sso.delete(sso.paths.setupLoginToken, {
      data: { token },
    })
    return response
  }

  login = async ({ username, password, captcha, site_key }: LoginData) => {
    return await this.sso.post(sso.paths.login, {
      user: username,
      password,
      captcha,
      site_key,
    })
  }

  logout = async () => {
    const response = await this.sso.post(sso.paths.logout)
    const redirect = getQueryVariable('redirect')

    const isValidRedirect = BrowserUtils.validateUrl(redirect)

    if (redirect && !isValidRedirect) return

    if (redirect) {
      window.location.href = `${this.ssoDoubleBase}${sso.paths.logout}${window.location.search}`
      return response
    }
    window.location.href = `${this.ssoDoubleBase}${
      sso.paths.logout
    }?redirect=${encodeURIComponent(
      `${window.location.origin}${window.location.search}`
    )}`
    return response
  }

  logoutDouble = async () => {
    return await axios.post(`${this.ssoBase}${sso.paths.logout}`, undefined, {
      withCredentials: true,
    })
  }

  getJWTToken = async () => {
    return await axios.get(`${this.ssoBase}${sso.paths.token}`, {
      withCredentials: true,
    })
  }

  redirectJWT = ({
    token,
    qs = window.location.search,
  }: {
    token: string
    qs: string
  }) => {
    const redirect = getQueryVariable('redirect', qs)

    const isValidRedirect = BrowserUtils.validateUrl(redirect)

    if (redirect && isValidRedirect) {
      localStorage.removeItem(storedOuterRedirectKey)
      localStorage.removeItem(storedInnerRedirectKey)
      window.location.href = `${this.ssoDoubleBase}${sso.paths.tokenLogin}/${token}${qs}`
      return
    }
    const innerRedirect = getQueryVariable('innerRedirect')
    // add redirect as full url if `innerRedirect`
    window.location.href = `${this.ssoDoubleBase}${
      sso.paths.tokenLogin
    }/${token}?redirect=${encodeURIComponent(
      `${window.location.origin}${innerRedirect ? innerRedirect : ''}`
    )}`
  }

  loginMFA = async ({ token }: LoginMFAData) => {
    return await this.sso.post(sso.paths.loginMFA, {
      token,
    })
  }

  getProfile = async () => {
    const user = await this.sso.get(sso.paths.self)
    return {
      ...user.data,
    }
  }

  updateProfile = async (data: ProfileData) => {
    return this.sso.put(sso.paths.self, data)
  }

  updateUserSettings = async (data: UserSettings) => {
    return this.sso.patch(sso.paths.updateProfile, data)
  }

  getTwoFaUri = async () => {
    const response = await this.sso.get(sso.paths.twoFa)
    return {
      ...response.data,
    }
  }

  getAssuranceLevel = async () => {
    const response = await this.sso.get(sso.paths.assuranceLevel)

    return { ...response.data }
  }

  upgradeAssuranceLevel = async (password: string) => {
    const response = await this.sso.post(sso.paths.assuranceLevel, {
      password,
    })
    return { ...response.data }
  }

  downgradeAssuranceLevel = async () => {
    const response = await this.sso.delete(sso.paths.assuranceLevel)

    return { ...response.data }
  }

  extendAssuranceLevel = async () => {
    const response = await this.sso.post(sso.paths.assuranceLevelRefresh)

    return { ...response.data }
  }

  sendResetPasswordEmail = async (data: {
    email: string
    captcha: string
    site_key: string
  }) => {
    const response = await this.sso.post(
      `${sso.paths.resetPassword}/${encodeURIComponent(data.email)}`,
      { captcha: data.captcha, site_key: data.site_key }
    )
    return {
      ...response.data,
    }
  }

  verifyResetRequest = async (uuid: string) => {
    const response = await this.sso.put(
      `${sso.paths.verifyResetRequest}/${uuid}`
    )
    return {
      ...response.data,
    }
  }

  verifyRegistration = async (uuid: string) => {
    const response = await this.sso.put(
      `${sso.paths.verifyRegistration}/${uuid}`
    )
    return {
      ...response.data,
    }
  }

  resetPassword = async (uuid: string, newPassword: string) => {
    const response = await this.sso.put(
      `${sso.paths.verifyResetRequest}/${uuid}`,
      { password: newPassword }
    )

    return {
      ...response.data,
    }
  }

  checkUsername = async (username: string) => {
    const response = await this.sso.get(
      `${sso.paths.checkUsername}/${username}`
    )
    return response.data
  }

  checkEmail = async (email: string) => {
    const response = await this.sso.get(`${sso.paths.checkEmail}/${email}`)
    return response.data
  }

  checkPassword = async (password: string, user_inputs?: string[]) => {
    const response = await this.sso.post(sso.paths.checkPassword, {
      password,
      user_inputs,
    })
    return response.data
  }

  checkAddress = async (addressValues: AddressToValidate) => {
    Object.keys(addressValues).forEach((key) => {
      if (addressValues[key as keyof AddressToValidate] === undefined) {
        delete addressValues[key as keyof AddressToValidate]
      }
    })
    const response = await this.sso.post(
      `${sso.paths.validateAddress}`,
      addressValues
    )
    return response.data
  }

  resendVerificationEmail = async ({
    usernameOrEmail,
    captcha,
    site_key,
  }: {
    usernameOrEmail: string
    captcha: string
    site_key: string
  }) => {
    const isUsername = usernameOrEmail.indexOf('@') === -1
    const path = sso.paths.resendVerificationEmail.replace(
      '{ref}',
      isUsername ? 'userid' : 'email'
    )
    const response = await this.sso.put(
      `${path}/${
        isUsername ? usernameOrEmail : encodeURIComponent(usernameOrEmail)
      }`,
      {
        captcha,
        site_key,
      }
    )
    return response
  }

  requestVerificationEmail = async (email: string) => {
    const response = await this.sso.post(sso.paths.requestVerifyEmail, {
      email,
    })
    return { ...response.data }
  }

  createLteSubscription = async (data: {
    iccid: string
    paymentSourceId: string
    controllerId: string
    controllerName: string
    siteId: string
    siteName: string
  }) => {
    return axios.post(
      `${lte.base}${lte.paths.devices}`,
      {
        ...data,
        imei: '000000000000000',
        firmwareVersion: '1.0.0',
        modelNumber: 'ULTE',
      },
      {
        withCredentials: true,
      }
    )
  }

  createZendeskRequest = async (values: {
    ticket_form_id: number
    requester?: {
      email?: string
      name?: string
    }
    tags: string[]
    subject?: string
    comment: { body?: string }
    custom_fields?: { id: number; value: string | boolean }[]
  }) => {
    const response = await axios.post(
      `${accountBE.base}/${accountBE.paths.zendeskCreateTicket}`,
      values,
      { withCredentials: true }
    )
    return response.data
  }

  createOrUpdateZendeskUser = async () => {
    const response = await this.sso.post(
      sso.paths.zendeskCreateOrUpdateUser,
      {}
    )
    return response.data
  }

  createSupportFile = async (deviceId: string) => {
    const pathWithParams = `${nca.paths.devices}/${deviceId}/support-files`
    const response = await aws
      .fetch(pathWithParams, { method: 'POST' })
      .then((res) => res.json())
    return response
  }

  getEncryptionKey = async () => {
    const pathWithParams = nca.paths.encryptionKey
    const response = await aws
      .fetch(pathWithParams, { method: 'GET' })
      .then((res) => res.json())
    return response
  }

  downloadBackup = async (
    backup: BackupItem,
    hashEncrypt: boolean,
    hash?: string
  ) => {
    const headers: Record<string, string> | undefined =
      hashEncrypt && hash ? { 'x-ui-password-hash': hash } : undefined
    const path = `${nca.paths.backup}/${backup.deviceId}/${encodeURIComponent(
      backup.key
    )}?hashEncrypt=${hashEncrypt}`

    const response = await aws
      .fetch(path, {
        method: 'GET',
        body: undefined,
        headers,
      })
      .then(async (res) => {
        const { status } = res

        if (status >= 400 && status < 500) {
          if (
            status === 400 &&
            res?.body instanceof ReadableStream &&
            res?.body.getReader instanceof Function
          ) {
            const { code = '' } = await new Response(res.body).json()
            if (code === ErrorCode.BACKUP_SIZE_LIMIT) {
              throw new Error(ErrorMessage.FILE_TOO_BIG)
            }
          }
          throw new Error(ErrorMessage.BAD_REQUEST)
        }

        if (status !== 200) throw new Error()
        return res.json()
      })

    return response
  }

  getLocationByIPAddress = async () =>
    await axios.get(`${geo.base}${geo.paths.geo}`)

  getTotalDevices = async (deviceIds: string[]) => {
    const pathWithParams = nca.paths.applicationDevices
    const response = await aws
      .fetch(pathWithParams, {
        method: 'POST',
        body: {
          deviceIds,
        },
      })
      .then((res) => res.json())
    return response
  }

  createUmrSupportFile = async (deviceId: string) => {
    const response = await axios.post(
      `${cap.base}${cap.paths.devices}/${deviceId}/support-files`,
      {},
      { withCredentials: true }
    )
    return response.data.data
  }

  submitMFALoginIssueFeedback = async (
    data: {
      name: string
      email: string
      message: string
      mfaId: string | null
      userAgent: string
    },
    captchaToken: string
  ) => {
    await axios.post(`${feedback.base}${feedback.paths.ssoIssue}`, data, {
      headers: { 'X-Captcha': captchaToken },
    })
  }

  mfaLoginIssueFeedbackIsSubmitted = async (data: { mfaId: string }) => {
    await axios.post(
      `${accountBE.base}${accountBE.paths.loginIssueFeedback}`,
      data
    )
  }

  getHasSubmittedMfaFeedbackRecently = async (mfaId: string) => {
    const response = await axios.get(
      `${accountBE.base}${accountBE.paths.loginIssueFeedback}/${mfaId}`
    )
    return response.data
  }
}

export default new Api()
