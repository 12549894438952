import { fetchRequestWithCookies } from './axios'

const {
  api: { sso, ssoUi },
} = __CONFIG__

interface LegalInfo {
  rev_privacy?: string
  rev_terms?: string
  rev_terms_aircrm?: string
}

export class LegalApi {
  get = async () => {
    const url = new URL(sso.paths.legal, ssoUi.base)
    const { data } = await fetchRequestWithCookies<LegalInfo>(url.href)
    return data
  }
}
