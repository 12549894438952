import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormikProps, withFormik } from 'formik'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { InfoIcon } from '@ubnt/icons'
import { Button } from '@ubnt/ui-components'
import Yup from 'validators/yupLocaleConfig'
import {
  resetProfileApiErrors,
  selectProfileData,
  selectProfileErrors,
  selectProfileIsLoading,
  selectProfileMailingList,
  updateProfileData,
  updateProfileSettings,
} from 'modules/profile'
import { ApiUpdateError, ProfileData, RootState } from 'types/types'
import { submitWithPasswordConfirmation } from 'components/password-modal/PasswordModal'
import { setVisibleModal } from 'modules/modals'
import {
  CheckIcon,
  Columns,
  ColumnsWithGap,
  EditIcon,
  FormContainer,
  GridWrapper,
  ImageValueWrapper,
  InputLabel,
  PictureLabel,
  StyledAvatar,
  StyledInput,
  StyledText,
  StyledTooltip,
  ValueWrapper,
} from 'pages/profile/Profile.styles'
import { PROFILE_PICTURE_MODAL_ID } from 'features/profile-picture/ui/ProfilePictureModal'
import { checkAuth } from 'features/auth/modules/auth'

import { CHANGE_USERNAME_MODAL_ID } from './ChangeUsernameModal'
import SelectLanguage from './SelectLanguage'
import EarlyAccessSection from './EarlyAccessSection'
import { FooterButtons } from 'components/FooterButtons'

type FormValues = ProfileData

interface FormProps extends ProfileData {
  handleSubmit(data: FormValues): void
  apiErrors: ApiUpdateError
  isLoading: boolean
  resetApiErrors: () => void
}

const { featureFlags } = __CONFIG__

const ProfileInfoForm: React.FC<
  FormProps & FormikProps<FormValues> & WrappedComponentProps
> = ({
  handleBlur,
  handleChange,
  values,
  isLoading,
  apiErrors,
  errors,
  handleSubmit,
  handleReset,
  dirty: hasChanges,
}) => {
  const dispatch = useDispatch()
  const hasErrors = !!Object.keys(errors).length
  const profileMailingList = useSelector(selectProfileMailingList)

  const onOpenChangeUsernameModal = useCallback(
    () => dispatch(setVisibleModal(CHANGE_USERNAME_MODAL_ID)),
    [dispatch]
  )

  const changeProfilePicture = () =>
    dispatch(setVisibleModal(PROFILE_PICTURE_MODAL_ID))

  const handleFormReset = () => {
    handleReset()
  }

  const toggleMailingList = useCallback(() => {
    //INFO: We toggle all three lists for now until we update the UI to allow multiple selections
    const newsletter = {
      announcements: !profileMailingList,
      unifi: !profileMailingList,
      uisp: !profileMailingList,
    }
    dispatch(updateProfileSettings({ newsletter }))
    dispatch(checkAuth())
  }, [profileMailingList, dispatch])

  return (
    <FormContainer>
      <GridWrapper>
        <PictureLabel>
          <FormattedMessage id="SETTINGS_PICTURE_LABEL" />
        </PictureLabel>
        <ImageValueWrapper>
          <StyledAvatar isClickable={false} />
          <Button
            variant="link"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              changeProfilePicture()
            }}
          >
            <EditIcon size="medium" />
            <FormattedMessage id="SETTINGS_PICTURE_UPLOAD_BUTTON" />
          </Button>
        </ImageValueWrapper>
      </GridWrapper>

      <GridWrapper>
        <InputLabel>
          <FormattedMessage id="COMMON_LABEL_USERNAME" />
        </InputLabel>
        <ValueWrapper>
          <StyledText>{values.username}</StyledText>
          <Button
            variant="link"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              onOpenChangeUsernameModal()
            }}
          >
            <EditIcon size="medium" />
            <FormattedMessage id="COMMON_LABEL_CHANGE_USERNAME" />
          </Button>
        </ValueWrapper>
      </GridWrapper>

      <GridWrapper>
        <InputLabel>
          <FormattedMessage id="COMMON_LABEL_EMAIL" />
          <StyledTooltip
            description={
              <FormattedMessage
                id="SETTINGS_PROFILE_CHANGE_EMAIL_TOOLTIP"
                values={{
                  a: (text) => <Link to="/security">{text}</Link>,
                }}
              />
            }
            portal
            isInteractive
          >
            <InfoIcon size="original" isActive variant="twoTone" />
          </StyledTooltip>
        </InputLabel>
        <ValueWrapper>
          <StyledText>{values.email}</StyledText>
        </ValueWrapper>
      </GridWrapper>

      <GridWrapper>
        <InputLabel>
          <FormattedMessage id="COMMON_LABEL_FIRST_NAME" />
        </InputLabel>
        <StyledInput
          variant="secondary"
          name="first_name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.first_name}
          invalid={errors?.first_name || apiErrors?.first_name}
          maxLength={30}
          width="100%"
        />
      </GridWrapper>

      <GridWrapper>
        <InputLabel>
          <FormattedMessage id="COMMON_LABEL_LAST_NAME" />
        </InputLabel>
        <StyledInput
          variant="secondary"
          name="last_name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.last_name}
          invalid={errors?.last_name || apiErrors?.last_name}
          maxLength={30}
          width="100%"
        />
      </GridWrapper>

      <SelectLanguage />

      <EarlyAccessSection />

      {featureFlags.optInMailingList && (
        <GridWrapper>
          <InputLabel>
            <FormattedMessage id="COMMON_AUTH_REGISTER_LABEL_NEWSLETTER_PROFILE" />
          </InputLabel>
          <Columns>
            {profileMailingList && <CheckIcon variant="twoTone" isActive />}
            <ColumnsWithGap>
              {profileMailingList && (
                <FormattedMessage id="SETTINGS_NEWSLETTER_SUBSCRIBED" />
              )}
              <Button
                loader={isLoading ? 'dots' : undefined}
                variant="inline"
                onClick={toggleMailingList}
              >
                <FormattedMessage
                  id={
                    profileMailingList
                      ? 'COMMON_ACTION_UNSUBSCRIBE'
                      : 'COMMON_ACTION_SUBSCRIBE'
                  }
                />
              </Button>
            </ColumnsWithGap>
          </Columns>
        </GridWrapper>
      )}
      <FooterButtons
        isActive={hasChanges}
        isDisabled={hasErrors}
        isLoading={isLoading}
        onSubmit={() => handleSubmit()}
        onCancel={handleFormReset}
      />
    </FormContainer>
  )
}

const WrappedProfileInfoForm = withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ username, email, first_name, last_name }) => ({
    username: username || '',
    email: email || '',
    first_name: first_name || '',
    last_name: last_name || '',
  }),
  handleSubmit: (
    values,
    { props: { handleSubmit, username, email, first_name, last_name } }
  ) => {
    const dirtyValues: ProfileData = {}
    if (username !== values?.username) dirtyValues.username = values.username
    if (email !== values?.email) dirtyValues.email = values.email
    if (first_name !== values?.first_name)
      dirtyValues.first_name = values.first_name
    if (last_name !== values?.last_name)
      dirtyValues.last_name = values.last_name
    handleSubmit(dirtyValues)
  },
  enableReinitialize: true,
  validationSchema: () =>
    Yup.object().shape({
      email: Yup.string().required().email().label('COMMON_LABEL_EMAIL'),
      first_name: Yup.string().max(30).trim().label('COMMON_LABEL_FIRST_NAME'),
      last_name: Yup.string().max(30).trim().label('COMMON_LABEL_LAST_NAME'),
    }),
  validate: (_values, { resetApiErrors, apiErrors }) => {
    Object.keys(apiErrors).length && resetApiErrors()
  },
})(injectIntl(ProfileInfoForm))

const mapStateToProps = (state: RootState) => ({
  ...selectProfileData(state),
  apiErrors: selectProfileErrors(state),
  isLoading: selectProfileIsLoading(state),
})

const mapDispatchToProps = {
  handleSubmit: (data: ProfileData) => {
    const SECURE_UPDATE_FOR = ['username', 'email']
    if (
      Object.keys(data).some((formField) =>
        SECURE_UPDATE_FOR.includes(formField)
      )
    ) {
      return submitWithPasswordConfirmation(data, (data: ProfileData) => {
        if (Object.keys(data).some((formField) => formField === 'email')) {
          localStorage?.setItem(
            'emailUpdated',
            JSON.stringify({ emailUpdated: true })
          )
        }
        return updateProfileData(data)
      })
    } else {
      return updateProfileData(data)
    }
  },
  resetApiErrors: resetProfileApiErrors,
}

export const ProfileInfoFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedProfileInfoForm)
