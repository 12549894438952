import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import Dropdown from '@ubnt/ui-components/Dropdown/Dropdown'
import { selectProfileIsLoading } from 'modules/profile'
import { GridWrapper, InputLabel } from 'pages/profile/Profile.styles'
import {
  getLanguageOptions,
  LocaleContext,
  SupportedLanguages,
} from 'modules/LocaleContext'

const SelectLanguage = () => {
  const { language, selectLanguage } = useContext(LocaleContext)
  const isLoading = useSelector(selectProfileIsLoading)
  const languageOptions = getLanguageOptions()

  const intl = useIntl()

  const onLanguageChange = useCallback(
    (language: SupportedLanguages) => {
      selectLanguage?.(language)
    },
    [selectLanguage]
  )

  return (
    <>
      <GridWrapper>
        <InputLabel>
          {intl.formatMessage({ id: 'COMMON_LABEL_LANGUAGE' })}
        </InputLabel>
        <Dropdown
          variant="secondary"
          id="language"
          onChange={(language) =>
            onLanguageChange(language.value as SupportedLanguages)
          }
          value={language}
          disabled={isLoading}
          options={languageOptions}
          width="100%"
        />
      </GridWrapper>
    </>
  )
}

export default SelectLanguage
