import { createSelector } from 'reselect'
import { all, spawn } from 'redux-saga/effects'
import { api } from 'api'

import createDataModule, {
  ModuleState,
  defaultInitialState,
} from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'

import { MFAAuthenticatorStatus } from '../enums/index'
import { selectProfileDefaultMFA } from './profile'
import { MFADataState, MFAType } from './types'

const {
  api: { sso },
} = __CONFIG__

export const mfaDataKey = 'mfa'

const MFAActions = {
  fetch: `${mfaDataKey}/FETCH`,
}

const initialState: ModuleState<MFADataState, SSOAPIError> = {
  ...defaultInitialState,
  data: {
    authenticators: [],
  },
}

const dataModule = createDataModule<MFADataState, SSOAPIError>(
  mfaDataKey,
  sso.paths.mfa,
  api.ssoBase,
  initialState
)

const { selectData } = dataModule

export const fetchMFA = () => {
  return { type: MFAActions.fetch, payload: {} }
}

export function* mfaRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}

export const mfaReducer = dataModule.reducer

export const selectMFAAuthenticators = createSelector(
  selectData,
  ({ authenticators }) =>
    authenticators.filter((authenticator) => authenticator)
)

export const selectPendingMFAAuthenticators = createSelector(
  selectMFAAuthenticators,
  selectProfileDefaultMFA,
  (authenticators, defaultMFAId) =>
    authenticators
      .filter(
        (method) =>
          method.id !== defaultMFAId &&
          method.status === MFAAuthenticatorStatus.PENDING &&
          !(
            method.type === MFAType.totp &&
            method.status === MFAAuthenticatorStatus.PENDING
          ) &&
          !(
            method.type === MFAType.push &&
            method.status === MFAAuthenticatorStatus.PENDING
          ) &&
          method.type !== MFAType.backupCodes
      )
      .sort(
        (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
      )
)

export const selectHasPendingMFAAuthenticators = createSelector(
  selectPendingMFAAuthenticators,
  (authenticators) => !!authenticators.length
)
