import { fetchRequestWithCookies } from './axios'

const {
  api: { umr },
} = __CONFIG__

type Location = {
  place: string
  region: string
  region_code: string
  country: string
  country_code: string
}

export type UmrDeviceData = {
  stripe_id: string
  device: {
    name: string
    location?: Location
  }
}

type UmrDevice = {
  data?: UmrDeviceData[]
}

export class UmrDeviceApi {
  get = async () => {
    const url = new URL(umr.paths.subscriptions, umr.base)
    const { data } = await fetchRequestWithCookies<UmrDevice>(url.href)
    return data.data ?? []
  }
}
